import React, { useState } from 'react';
import { Modal } from 'antd';
import MissingDocumentsList from './MissingDocumentsList';

export default function MissingDocumentsModal({ record, applicationID, fullName, id }) {
	const [visible, setVisible] = useState(false);
	const { stages } = record;

	return (
		<>
			<a onClick={() => setVisible(true)}>Show Documents</a>
			{visible ? (
				<Modal
					open={visible}
					title={
						<span className="expiring-tickets-modal-name">
							<p>Applicant Name - {fullName} &nbsp;</p>
							<p>#{applicationID.toUpperCase()}</p>
						</span>
					}
					footer={null}
					onCancel={() => setVisible(false)}>
					<MissingDocumentsList stages={stages} />
				</Modal>
			) : null}
		</>
	);
}
